import React, { useContext, useEffect, useState } from "react";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Footer from "../Comman/Footer";
import { useParams } from "react-router";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Copy from "../Comman/Copy";
import StakingIncome from "./StakingIncome";
import LevelIncome from "./LevelIncome";
import RankIncome from "./RankIncome";
import CommunityIncome from "./CommunityIncome";
import RoiOfRoi from "./RoiOfRoi";
import Stakings from "./Stakings";
import Unstake from "./Unstake";
import Withdrawal from "./Withdrawal";
import PendingWithdrawal from "./PendingWithdrawal";
import Statements from "./Statements";

export default function MemberReport() {
  const { address } = useParams();
  const { formatAddress } = useContext(GMCContext);
  const [data, setdata] = useState([]);
  const [totReward, setTotReward] = useState(0);

  const getData = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "getbyid",
        key: process.env.REACT_APP_KEY,
        address,
      })
      .then((res) => {
        if (res.data.data) {
          var fbln =
            res.data.data.level_rew +
            res.data.data.staking_rew +
            res.data.data.heritage_rew +
            res.data.data.community_rew +
            res.data.data.community_royalty -
            res.data.data.t_cold_withdrawal -
            res.data.data.t_other_withdrawal;
          setTotReward(fbln);
          setdata(res.data.data);
        }
        if (res.data.error) {
          return "";
        }
      });
  };
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address]);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="#">Member Report</a>
                </li>
              </ol>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="profile-statistics">
                  <div className="row">
                    <div className="col-12 col-md-3 mb-4">
                      <p className="text-center w-100">User Id</p>
                      <h5 className="m-b-0 text-center">
                        {data?.user_id ? data?.user_id : "-"}
                      </h5>
                    </div>
                    <div className="col-12 col-md-3 mb-4">
                      <p className="text-center w-100">Address</p>
                      <h5 className="m-b-0 text-center">
                        <a
                          target="_blank"
                          className="text-primary"
                          href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                          rel="noreferrer"
                        >
                          {formatAddress(address)}
                        </a>
                        {address ? <Copy data={address} /> : ""}
                      </h5>
                    </div>
                    <div className="col-12 col-md-3 mb-4">
                      <p className="text-center">Ref Id</p>
                      <h5 className="m-b-0 text-center">
                        {data?.referral_id ? data?.referral_id : "-"}
                      </h5>
                    </div>
                    <div className="col-12 col-md-3 mb-4">
                      <p className="text-center">Ref Address</p>
                      <h5 className="m-b-0 text-center">
                        {data?.ref_address ? (
                          <>
                            <a
                              target="_blank"
                              className="text-primary"
                              href={`${process.env.REACT_APP_EXPLORER}address/${address}`}
                              rel="noreferrer"
                            >
                              {data?.ref_address ? formatAddress(data?.ref_address) : ""}
                            </a>
                            {data?.ref_address ? <Copy data={data?.ref_address} /> : ""}
                          </>
                        ) : (
                          "-"
                        )}
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">User Name</p>
                          <h5 className="text-center">{data?.uname ? data?.uname : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Display Name</p>
                          <h5 className="text-center">{data?.dname ? data?.dname : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Ref.Username</p>
                          <h5 className="text-center">{data?.ref_uname ? data?.ref_uname : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Ref.Display Name</p>
                          <h5 className="text-center">{data?.ref_dname ? data?.ref_dname : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Investment</p>
                          <h5 className="text-center">{data?.t_staking ? data?.t_staking : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Directs</p>
                          <h5 className="text-center">{data?.d_team ? data?.d_team : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team</p>
                          <h5 className="text-center">{data?.team ? data?.team : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Direct Business</p>
                          <h5 className="text-center">$ {data?.direct_team_business ? data?.direct_team_business : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team Business (L1,L2)</p>
                          <h5 className="text-center">$ {data?.l1_tb + data?.l2_tb}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Community Team Business (From L3)</p>
                          <h5 className="text-center">$ {data?.new_team_business_third ? data?.new_team_business_third : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Rank</p>
                          <h5 className="text-center">{data?.rank ? data?.rank : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Next Rank</p>
                          <h5 className="text-center">{data?.next_rank ? data?.next_rank : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Start Date</p>
                          <h5 className="text-center">{data?.s_date ? data?.s_date : "-"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team-A</p>
                          <h5 className="text-center">$ {data?.aleg ? data?.aleg : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team-B</p>
                          <h5 className="text-center">
                            ${data?.bleg ? data?.bleg : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team-C</p>
                          <h5 className="text-center">$ {data?.oleg ? data?.oleg : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req.Team-A</p>
                          <h5 className="text-center">
                            ${data?.r_aleg ? data?.r_aleg : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req.Team-B</p>
                          <h5 className="text-center">${data?.r_bleg ? data?.r_bleg : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req.Team-B</p>
                          <h5 className="text-center">$ {data?.r_oleg ? data?.r_oleg : "0"}</h5>
                        </div>
                        
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Team Revenue</p>
                          <h5 className="text-center">$ {data?.roiofroi_inc ? data?.roiofroi_inc : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Community Bonus</p>
                          <h5 className="text-center">
                            ${data?.community_inc ? data?.community_inc : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Leadership Bonus</p>
                          <h5 className="text-center">${data?.rank_inc ? data?.rank_inc : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Req.Team-B</p>
                          <h5 className="text-center">$ {data?.r_oleg ? data?.r_oleg : "0"}</h5>
                        </div>


                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Achievement</p>
                          <h5 className="text-center">
                            ${data?.rank_number_title ? data?.rank_number_title : "0"}
                          </h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Gaming Wallet</p>
                          <h5 className="text-center">${data?.gaming_wallet ? data?.gaming_wallet : "0"}</h5>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                          <p className="text-center w-100">Withdrawal</p>
                          <h5 className="text-center">$ {data?.t_withdrawal ? data?.t_withdrawal : "0"}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <StakingIncome address={address} />
            <LevelIncome address={address} />
            <RankIncome address={address} />
            <CommunityIncome address={address} />
            <RoiOfRoi address={address} />
            <Stakings address={address} />
            <Unstake address={address} />
            <Withdrawal address={address} />
            <PendingWithdrawal address={address} />
            <Statements address={address} />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
