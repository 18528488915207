import Index from "./Components/Home/Index";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Members from "./Components/Members/Members";
import StakingIncome from "./Components/Report/StakingIncome";
import Withdrawal from "./Components/Report/Withdrawal";
import MemberReport from "./Components/Members/MemberReport";
import Login from "./Components/Login";
import LevelIncome from "./Components/Report/LevelIncome";
import RankIncome from "./Components/Report/RankIncome";
import CommunityIncome from "./Components/Report/CommunityIncome";
import RoiOfRoi from "./Components/Report/RoiOfRoi";
import Stakings from "./Components/Report/Stakings";
import Unstake from "./Components/Report/Unstake";
import PendingWithdrawal from "./Components/Report/PendingWithdrawal";
import Silver from "./Components/Ranks/Silver";
import Gold from "./Components/Ranks/Gold";
import Pearl from "./Components/Ranks/Pearl";
import Ruby from "./Components/Ranks/Ruby";
import Emerald from "./Components/Ranks/Emerald";
import Sapphire from "./Components/Ranks/Sapphire";
import Diamond from "./Components/Ranks/Diamond";
import BlueDiamond from "./Components/Ranks/BlueDiamond";
import RedDiamond from "./Components/Ranks/RedDiamond";
import RoyalDiamond from "./Components/Ranks/RoyalDiamond";
import BlockA from "./Components/Block/BlockA";
import BlockB from "./Components/Block/BlockB";
import Cron from "./Components/Cron";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Index />} />
        <Route path="/users" element={<Members />} />
        <Route path="/userreport/:address" element={<MemberReport />} />
        <Route path="/investment-reward" element={<StakingIncome />} />
        <Route path="/team-reward" element={<LevelIncome />} />
        <Route path="/community-reward" element={<CommunityIncome />} />
        <Route path="/leadership-reward" element={<RankIncome />} />
        <Route path="/achievements" element={<RoiOfRoi />} />
        <Route path="/investments" element={<Stakings />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/withdrawal" element={<Withdrawal />} />
        <Route path="/pending-withdrawal" element={<PendingWithdrawal />} />
        {/* <Route path="/statements" element={<Statements />} /> */}

        {/* rank wise users */}
        <Route path="/player" element={<Silver />} />
        <Route path="/captain" element={<Gold />} />
        <Route path="/commander" element={<Pearl />} />
        <Route path="/legend" element={<Ruby />} />
        <Route path="/super-start" element={<Emerald />} />
        <Route path="/master" element={<Sapphire />} />
        <Route path="/ambassador" element={<Diamond />} />
        <Route path="/d-ambassador" element={<BlueDiamond />} />
        <Route path="/c-ambassador" element={<RedDiamond />} />
        <Route path="/president" element={<RoyalDiamond />} />
        <Route path="/block-a" element={<BlockA />} />
        <Route path="/block-b" element={<BlockB />} />
        <Route path="/crons" element={<Cron />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
