import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Comman/Sidebar";
import Header from "../Comman/Header";
import Footer from "../Comman/Footer";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Web3 from "web3";
const web3 = new Web3(process.env.REACT_APP_RPC);

export default function Index() {
  const { toastSuccess, toastError } = useContext(GMCContext);
  const [dashboardData, setDashboardData] = useState(null);
  const [settingData, setSettingData] = useState(null);
  const [ownerContractBal, setOwnerContractBal] = useState(0);
  const [uaddress, setuaddress] = useState("");
  const [amount, setamount] = useState(0);
  const [withdrawal, setwithdrawal] = useState(0);

  const getDashboardData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "user",
          submethod: "admindashd",
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          console.log("res.data", res.data.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            setDashboardData(res.data.data);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  const getSettingData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "setting",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            setSettingData(res.data.data);
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };
  const updateSettingData = async () => {
    try {
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "setting",
          submethod: "update",
          key: process.env.REACT_APP_KEY,
          status: !settingData.withdrawal,
        })
        .then((res) => {
          // console.log("res.data", res.data);
          if (res.data.error) {
            console.log("res.data.error", res.data.error);
          } else {
            getSettingData();
          }
        });
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getDashboardData();
    getSettingData();
  }, []);

  const getContractBalance = async () => {
    try {
      if (settingData?.contract && settingData?.contract_abi) {
        const balwei = await web3.eth.getBalance(process.env.REACT_APP_AFFILATE_OWNER);
        const bal = await web3.utils.fromWei(balwei, "ether");
        setOwnerContractBal(bal);
      }
    } catch (err) {
      console.log("error : ", err);
    }
  };

  useEffect(() => {
    getContractBalance();
  }, [settingData]);


  const onDeposit = async () => {

    async function main() {
      try {
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "transaction",
            submethod: "insertadmin",
            key: process.env.REACT_APP_KEY,
            address: uaddress,
            amount: amount,
            w_amount: withdrawal,
          })
          .then((res) => {
            // console.log("data", uaddress);
            // console.log("data", amount);

            if (res.data.error) {
              toastError(res.data.error);
              return "";
            }
            toastSuccess("Transaction done");

          });

      } catch (error) {
        return;
      }
    }
    main();
  };
  const [showTopUp, setShowTopUp] = useState(false);
  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className={showTopUp ? "modal fade show d-block" : "modal fade"} id="replyModal">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Top Up</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => setShowTopUp(!showTopUp)} />
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="mb-3 col-md-12">
                    <label className="form-label">Username</label>
                    <input type="text" placeholder="Username" value={uaddress} onChange={(e) => setuaddress((e.target.value).replace(/\s/g, ""))} className="form-control" />
                  </div>
                  {/* <div className="mb-3 col-md-12">
                    <label className="form-label">Ref. Address</label>
                    <input type="password" placeholder="Ref. Address" className="form-control" />
                  </div> */}
                  <div className="mb-3 col-md-12">
                    <label className="form-label">Staking Amount</label>
                    <input type="text" placeholder="Staking Amount" value={amount} onChange={(e) => setamount(e.target.value)} className="form-control" />
                  </div>
                  <div className="mb-3 col-md-12">
                    <label className="form-label">Withdrawal Amount</label>
                    <input type="number" placeholder="Withdrawal Amount" value={withdrawal} onChange={(e) => setwithdrawal(e.target.value)} className="form-control" />
                  </div>
                </div>

              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => onDeposit()}>Submit</button>
              </div>
            </div>
          </div>
        </div>

        <div className="content-body">
          <div className="container-fluid">
            <div className="form-head d-flex mb-3 mb-md-4 align-items-start">
              <div className="me-auto d-lg-flex justify-content-between">
                <h3 className="text-black font-w600">Welcome to YBLWin!</h3>
                <button
                  type="button"
                  className={`btn btn-${settingData?.withdrawal === true ? "success" : "danger"} btn-sm p-1 px-3 mx-2`}
                  onClick={() => setShowTopUp(!showTopUp)}
                >Top Up
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Withdrawal Status</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {settingData ? settingData?.withdrawal === true ? "Enabled" : "Disabled" : ''}
                            <span className="fs-12">
                              <button
                                type="button"
                                className={`btn btn-${settingData?.withdrawal === true ? "success" : "danger"} btn-sm p-1 px-2 mx-2`}
                                onClick={() => updateSettingData()}
                              >
                                <i
                                  className={`fa fa-${settingData?.withdrawal === true ? "check" : "x"} fs-18`}
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </span>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData ? dashboardData?.totalusers : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Active Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData ? dashboardData?.totalactiveusers : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.stakingincome ? dashboardData?.stakingincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Team Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.levelincome ? dashboardData?.levelincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Leadership Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.communityincome ? dashboardData?.communityincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Community Reward</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.communityincome ? dashboardData?.communityincome : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking($)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            ${dashboardData?.t_staking ? dashboardData?.t_staking : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Staking</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.t_staking_token ? dashboardData?.t_staking_token : 0} {process.env.REACT_APP_TICKER}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Unstake</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.unstake ? dashboardData?.unstake : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Block A(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.block_a ? dashboardData?.block_a : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Block B(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.block_b ? dashboardData?.block_b : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">2X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user4x ? dashboardData?.user4x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">3X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user3x ? dashboardData?.user3x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">6X(Users)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.user6x ? dashboardData?.user6x : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total BNB</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.bnbs ? dashboardData?.bnbs : 0} BNB
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.withdrawal ? dashboardData?.withdrawal : 0} {process.env.REACT_APP_TICKER}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Withdrawal($)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            ${dashboardData?.t_withdrawal ? dashboardData?.t_withdrawal : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Pending Withdrawal($)</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            ${dashboardData?.p_withdral ? dashboardData?.p_withdral : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Contract Balance</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {ownerContractBal ? ownerContractBal : 0} BNB
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Total Restake</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            $ {dashboardData?.totalrestake ? dashboardData?.totalrestake : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white bg-info">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">Restake Users</p>
                        <div className="d-flex flex-wrap">
                          <h2 className="fs-40 font-w600 text-white mb-0 me-3">
                            {dashboardData?.intotalrestakeusers ? dashboardData?.intotalrestakeusers : 0}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-xl-3 col-xxl-3 col-sm-6">
                <div className="card gradient-bx text-white">
                  <div className="card-body">
                    <div className="media align-items-center">
                      <div className="media-body">
                        <p className="mb-1">User Smart Contract</p>
                        <div className="d-flex flex-wrap">
                          {!loading ? (
                            <button
                              type="button"
                              className="btn btn-primary btn-sm"
                              data-bs-toggle="modal"
                              data-bs-target="#confirmUpd"
                            >
                              <i className="fa fa-plus " aria-hidden="true" />
                            </button>
                          ) : (
                            ""
                          )}
                          <div>{loading ? "Data updating....." : ""}</div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <GoldRequest /> */}
          </div>
        </div>
        {/* <div className="modal fade" id="confirmUpd">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Are you sure??</h5>
                <div className="modal-footer">
                  <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">
                    Close
                  </button>
                  <button type="button" className="btn btn-primary" onClick={() => getCheck()} data-bs-dismiss="modal">
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <Footer />
      </div>
    </>
  );
}
