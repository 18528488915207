import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Header from "../Comman/Header";
import Sidebar from "../Comman/Sidebar";
import Copy from "../Comman/Copy";
import Footer from "../Comman/Footer";

export default function StakingIncome({ address }) {
  const { formatAddress, web3, contract, formatDateTime } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const getData = async () => {
    try {
      setLoading(true);
      await axios
        .post(process.env.REACT_APP_BACKEND_LINK, {
          method: "transaction",
          submethod: "get",
          key: process.env.REACT_APP_KEY,
          search: search,
          pageSize: pageSize,
          page: currentPage,
        })
        .then((res) => {
          console.log("staking income-->", res.data.data);
          setdata(res.data.data);
          setPages(res.data.pages);
          setLoading(false);
          if (res.data.error) {
            return "";
          }
        });
    } catch (err) {
      console.log("error : ", err);
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [address, pageSize, currentPage]);

  useEffect(() => {
    getData();
  }, [search]);

  return (
    <>
      <div id="main-wrapper">
        <Header />
        <Sidebar />
        <div className="content-body">
          <div className="container-fluid">
            <div className="page-titles">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/dashboard">Dashboard</a>
                </li>
                <li className="breadcrumb-item active text-primary">Investment Reward</li>
              </ol>
            </div>
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="card-body p-0">
                    <div className="form-head d-flex align-items-center">
                      <div className="input-group search-area d-inline-flex m-3">
                        <input
                          type="text"
                          className="form-control form-control-rev"
                          placeholder="Search here"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="input-group-append">
                          <button type="button" className="input-group-text input-group-text-rev" onClick={getData}>
                            <i className="flaticon-381-search-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <h3 class="py-3 px-5">Investment Reward</h3>
                    <hr />
                    <div className="table-responsive">
                      <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Hash</th>
                            <th>Userid(Address)</th>
                            <th>Inv.Amt</th>
                            <th>Reward(%)</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data && data?.length
                            ? data.map((e, i) => {
                              let ind = currentPage * pageSize - pageSize + (i + 1);
                              return (
                                <tr>
                                  <td>{ind}</td>
                                  <td className="text-primary">
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}tx/${e.hash}`}
                                      rel="noreferrer"
                                    >
                                      {formatAddress(e.hash)}
                                    </a>
                                    {e.hash ? <Copy data={e.hash} /> : ""}
                                  </td>
                                  <td>
                                    <a
                                      target="_blank"
                                      className="text-primary"
                                      href={`${process.env.REACT_APP_EXPLORER}address/${e.address}`}
                                      rel="noreferrer"
                                    >
                                      {e.user_id}
                                      {formatAddress(e.address)}
                                    </a>
                                    {e.address ? <Copy data={e.address} /> : ""}
                                  </td>
                                  <td>$ {e.staking}</td>
                                  <td>
                                    $ {e.income} ({e.income_per}%)
                                  </td>
                                  <td>{e.datetime}</td>
                                  <td>{e.status === 1 ? "Active" : "Deactive"}</td>
                                </tr>
                              );
                            })
                            : ""}
                        </tbody>
                      </table>
                      {loading ? (
                        <div className="text-center">
                          <p>Loading...</p>
                        </div>
                      ) : !data || !data.length ? (
                        <div className="text-center">
                          <p>No data found.</p>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Pagination
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      pageSize={pageSize}
                      setPageSize={setPageSize}
                      pages={pages}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
