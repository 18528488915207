import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import parse from "html-react-parser";
import { GMCContext } from "../../GMCContext";
import { FaUsers } from "react-icons/fa";
import { FaRankingStar } from "react-icons/fa6";
import { PiCoinsLight } from "react-icons/pi";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { GiRank3 } from "react-icons/gi";
import { TbBuildingCommunity } from "react-icons/tb";
import { MdOutlinePendingActions } from "react-icons/md";
import { IoIosList } from "react-icons/io";
import { GiMining } from "react-icons/gi";
import { WiSnowflakeCold } from "react-icons/wi";
import { GrStakeholder } from "react-icons/gr";
import { FaCubes } from "react-icons/fa";

export default function Sidebar() {
  const { setToggleAchieveRank, toggleAchieveRank } = useContext(GMCContext);
  const epoint = useLocation().pathname;

  const clearToggle = () => {
    setToggleAchieveRank("");
  };

  return (
    <>
      <div className="deznav">
        <div className="deznav-scroll">
          <ul className="metismenu" id="menu">
            <li className={`${epoint === "/dashboard" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link className={`ai-icon `} to="/dashboard" aria-expanded="false">
                <RxDashboard color="white" size="1.2rem" />
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/users" || epoint.includes("/userreport") ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/users" className="ai-icon" aria-expanded="false">
                <FaUsers color="white" size="1.2rem" />
                <span className="nav-text">Users</span>
              </Link>
            </li>
            <li className={toggleAchieveRank === "achieve-rank" ? "mm-active" : ""}>
              <Link
                className="has-arrow ai-icon"
                onClick={() => setToggleAchieveRank(toggleAchieveRank === "achieve-rank" ? "" : "achieve-rank")}
              >
                <FaRankingStar color="white" size="1.2rem" />
                <span className="nav-text">Achieve Rank</span>
              </Link>
              <ul style={toggleAchieveRank === "achieve-rank" ? {} : { height: "16px", display: "none" }}>
                <li className={`${epoint === "/player" ? "mm-active" : ""}`}>
                  <Link to="/player" className="ai-icon">
                    <span className="nav-text">Player</span>
                  </Link>
                </li>
                <li className={`${epoint === "/captain" ? "mm-active" : ""}`}>
                  <Link to="/captain" className="ai-icon">
                    <span className="nav-text">Captain</span>
                  </Link>
                </li>
                <li className={`${epoint === "/commander" ? "mm-active" : ""}`}>
                  <Link to="/commander" className="ai-icon">
                    <span className="nav-text">Commander</span>
                  </Link>
                </li>
                <li className={`${epoint === "/legend" ? "mm-active" : ""}`}>
                  <Link to="/legend" className="ai-icon">
                    <span className="nav-text">Legend</span>
                  </Link>
                </li>
                <li className={`${epoint === "/super-start" ? "mm-active" : ""}`}>
                  <Link to="/super-start" className="ai-icon">
                    <span className="nav-text">Super Start</span>
                  </Link>
                </li>
                <li className={`${epoint === "/master" ? "mm-active" : ""}`}>
                  <Link to="/master" className="ai-icon">
                    <span className="nav-text">Master</span>
                  </Link>
                </li>
                <li className={`${epoint === "/ambassador" ? "mm-active" : ""}`}>
                  <Link to="/ambassador" className="ai-icon">
                    <span className="nav-text">Ambassador</span>
                  </Link>
                </li>
                <li className={`${epoint === "/d-ambassador" ? "mm-active" : ""}`}>
                  <Link to="d-ambassador" className="ai-icon">
                    <span className="nav-text">D.Ambassador</span>
                  </Link>
                </li>
                <li className={`${epoint === "/c-ambassador" ? "mm-active" : ""}`}>
                  <Link to="/c-ambassador" className="ai-icon">
                    <span className="nav-text">C.Ambassador</span>
                  </Link>
                </li>
                <li className={`${epoint === "/president" ? "mm-active" : ""}`}>
                  <Link to="/president" className="ai-icon">
                    <span className="nav-text">President</span>
                  </Link>
                </li>
              </ul>
            </li>
            <li className={`${epoint === "/investment-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/investment-reward" className="ai-icon" aria-expanded="false">
                <PiCoinsLight color="white" size="1.2rem" />
                <span className="nav-text">Investment Reward</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/team-reward" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/team-reward" className="ai-icon" aria-expanded="false">
                <FaMoneyBillTrendUp color="white" size="1.2rem" />
                <span className="nav-text">Team Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/community-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/community-reward" className="ai-icon" aria-expanded="false">
                <GiRank3 color="white" size="1.2rem" />
                <span className="nav-text">Community Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/leadership-reward" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/leadership-reward" className="ai-icon" aria-expanded="false">
                <GiMining color="white" size="1.2rem" />
                <span className="nav-text">Leadership Reward</span>
              </Link>
            </li>
            <li className={`${epoint === "/achievements" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/achievements" className="ai-icon" aria-expanded="false">
                <WiSnowflakeCold color="white" size="1.2rem" />
                <span className="nav-text">Achievements</span>
              </Link>
            </li>
            <li className={`${epoint === "/investments" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/investments" className="ai-icon" aria-expanded="false">
                <GrStakeholder color="white" size="1.2rem" />
                <span className="nav-text">Investments</span>
              </Link>
            </li>
            {/* <li className={`${epoint === "/unstake" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/unstake" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-id-card-4" />
                <span className="nav-text">Unstake</span>
              </Link>
            </li> */}
            <li className={`${epoint === "/withdrawal" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/withdrawal" className="ai-icon" aria-expanded="false">
                <i className="flaticon-381-gift" />
                <span className="nav-text">Withdrawal</span>
              </Link>
            </li>
            <li
              className={`${epoint === "/pending-withdrawal" ? "mm-active active-no-child" : ""}`}
              onClick={clearToggle}
            >
              <Link to="/pending-withdrawal" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Pending-Withdrawal</span>
              </Link>
            </li>
            <li className={`${epoint === "/crons" ? "mm-active active-no-child" : ""}`} onClick={clearToggle}>
              <Link to="/crons" className="ai-icon" aria-expanded="false">
                <MdOutlinePendingActions color="white" size="1.2rem" />
                <span className="nav-text">Crons</span>
              </Link>
            </li>
            {/* <li className={`${epoint === "/statements" ? "mm-active active-no-child" : ""}`}>
              <Link to="/statements" className="ai-icon" aria-expanded="false">
                <IoIosList color="white" size="1.2rem" />
                <span className="nav-text">Statements</span>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
