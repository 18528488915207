import React from "react";

export default function Footer() {
  const today = new Date();
  const year = today.getFullYear();
  return (
    <>
      <div className="footer">
        <div className="copyright">
          <p>
            Copyright © All Rights Reserved by{" "}
            <a href="/" target="_blank">
              YBLWin
            </a>{" "}
            {year}
          </p>
        </div>
      </div>
    </>
  );
}
