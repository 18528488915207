import React, { useContext, useEffect, useState } from "react";
import { GMCContext } from "../../GMCContext";
import axios from "axios";
import Pagination from "../Comman/Pagination";
import Copy from "../Comman/Copy";

export default function RankIncome({ address }) {
  const { formatAddress, formatDateTime, copyText, Ranks } = useContext(GMCContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [level, setlevel] = useState(1);
  const [fromdate, setfromdate] = useState(null);
  const [todate, settodate] = useState(null);
  const [selectedP, setselectedP] = useState(0);
  const [selectedSrank, setselectedSrank] = useState(0);
  const [selectedPvalue, setselectedPvalue] = useState(null);
  const [selectedStatus, setselectedStatus] = useState(0);

  const getData = async () => {
    if (!address) {
      return;
    }
    setisLoading(true);
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "level",
        // address: "0xfded9e02beccedbddf19a1cf3a86769fca363427",
        address: address,
        level: level,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: 1000,

        select_item: selectedP,
        select_value: selectedPvalue,

        fromdate: fromdate,
        todate: todate,
        srank: selectedSrank,
        sstatus: selectedStatus
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        console.log("my all team", res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    if (address) {
      getData();
    }
  }, [address, pageSize, currentPage]);
  useEffect(() => {
    getData();
  }, [search]);
  console.log("rank --->", Ranks);
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body p-0">

              <div className="form-head d-flex align-items-center">

                <div className="input-group search-area d-inline-flex m-3">
                  <input
                    type="text"
                    className="form-control form-control-rev"
                    placeholder="Search here"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button type="button" className="input-group-text input-group-text-rev" onClick={getData}>
                      <i className="flaticon-381-search-2" />
                    </button>
                  </div>
                </div>
              </div>
              <div className='row d-flex align-items-center justify-content-around px-5'>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>Level</label>
                  <div className="form-group">
                    <input className="form-control" type="text" placeholder="Enter level" value={level}
                      onChange={(e) => setlevel(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>From</label>
                  <div className="form-group">
                    <input className="form-control" type="date" placeholder="Enter level" value={fromdate} onChange={(e) => setfromdate(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>To</label>
                  <div className="form-group">
                    <input className="form-control" type="date" placeholder="Enter level" value={todate} onChange={(e) => settodate(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>Select</label>
                  <div className="form-group">
                    <select className="default-select  form-control wide" aria-label="Default select example" onChange={(e) => setselectedP(Number(e.target.value))}>
                      <option selected="selected" value={0}>---Select--</option>
                      <option value={1}>Username</option>
                      <option value={2}>Display Name</option>
                    </select>
                  </div>
                </div>
                {selectedP !== 0 ?
                  <div className="col-lg-2">
                    <div className="form-group">
                      <label htmlFor="" className='text-start text-white'>Enter Value</label>
                      <input className="form-control" type="text" placeholder="Enter value" value={selectedPvalue}
                        onChange={(e) => setselectedPvalue(e.target.value)} />
                    </div>
                  </div> : ''}
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>Rank</label>
                  <div className="form-group">
                    <select className="default-select  form-control wide" aria-label="Default select example" onChange={(e) => setselectedSrank(Number(e.target.value))}>
                      <option selected="selected" value={0}>---Select--</option>
                      {Ranks.map((e) => {
                        console.log(e.rank_number)
                        return (<option value={e.rank_number}>{e.rank}</option>);
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>Status</label>
                  <div className="form-group">
                    <select className="default-select  form-control wide" aria-label="Default select example" onChange={(e) => setselectedStatus(Number(e.target.value))}>
                      <option selected="selected" value={0}>---All--</option>
                      <option value={1}>Active</option>
                      <option value={2}>Not Active</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3">
                  <button onClick={() => getData()} className="btn btn-success">Search NOW</button>
                </div>
              </div>
              {/* <div className='row d-flex justify-content-end'>

                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>Rank</label>
                  <div className="form-group">
                    <input className="form-control" type="text" placeholder="Enter level" value={level}
                      onChange={(e) => setlevel(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>From</label>
                  <div className="form-group">
                    <input className="form-control" type="date" placeholder="Enter level" value={fromdate}
                      onChange={(e) => setfromdate(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2">
                  <label htmlFor="" className='text-start text-white'>To</label>
                  <div className="form-group">
                    <input className="form-control" type="date" placeholder="Enter level" value={todate}
                      onChange={(e) => settodate(e.target.value)} />
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="basic-form">
                    <label htmlFor="" className='text-start text-white'>Select</label>
                    <select className="default-select  form-control wide">
                      <option selected="selected">Subject</option>
                      <option value={1}>Esports</option>
                      <option value={2}>Tournament</option>
                      <option value={3}>Sports</option>
                    </select>
                  </div>
                </div>
                <div className="col-lg-3 d-flex align-items-center">
                  <button onClick={() => getData()} class="btn btn-success">Search NOW</button>
                </div>
              </div> */}
              <h3 class="py-3 px-5">My All Team</h3>
              <hr />
              <div className="table-responsive">
                <table id="example5" className="table table-striped patient-list mb-4 dataTablesCard fs-14">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Joining Date</th>
                      <th scope="col">Username</th>
                      <th scope="col">Display Name</th>
                      <th scope="col">Direct Team</th>
                      <th scope="col">Direct Business</th>
                      <th scope="col">Rank</th>
                      <th scope="col">Referral</th>
                      <th scope="col">Package</th>
                      <th scope="col">Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td className="text-center" colSpan={10}>
                        {isLoading ? "Data is loading" : ""}
                      </td>
                    </tr>
                    {!isLoading ? (
                      !data.length ? (
                        <tr className="text-center">
                          <td className="text-center" colSpan={10}>
                            <span className="w-100">No data found</span>
                          </td>
                        </tr>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {data.map((ele, i) => {
                      return (
                        <tr>
                          <td>
                            {i + 1}
                          </td>
                          <td>
                            {ele.createdAt}
                          </td>
                          <td>
                            <span onClick={() =>
                              // copyText(ele.address)
                              copyText(ele.uname)
                            }>
                              {/* {formatAddress(ele.address)}  <i className="fa fa-copy"></i> */}
                              {/* {formatAddress(ele.uname)}  <i className="fa fa-copy"></i> */}
                              {ele.uname}
                            </span>
                          </td>
                          <td>
                            <span onClick={() =>
                              // copyText(ele.ref_address)
                              copyText(ele.dname)
                            }>
                              {/* {formatAddress(ele.ref_address)}  <i className="fa fa-copy"></i> */}
                              {/* {ele.ref_uname} */}
                              {ele.dname}
                            </span>
                          </td>
                          <td>
                            {ele.ref_team}
                          </td>
                          <td>
                            $ {ele.direct_team_business}
                          </td>
                          <td>
                            {ele.rank ? ele.rank : "No Rank"}
                          </td>
                          <td>
                            {ele.referral_uname}
                          </td>
                          <td>
                            $ {ele.t_staking}
                          </td>
                          <td>
                            {ele.status ? (
                              <div className="text-success">
                                Active
                              </div>
                            ) : (
                              <div className="text-danger">
                                Not Active
                              </div>
                            )}
                          </td>
                          {/* <td>
                                                    $ {ele.team_business} ({ele.team})
                                                </td> */}


                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                {loading ? (
                  <div className="text-center">
                    <p>Loading...</p>
                  </div>
                ) : !data || !data.length ? (
                  <div className="text-center">
                    <p>No data found.</p>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                pages={pages}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
